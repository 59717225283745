import Chart from 'chart.js/auto';
// import { getRelativePosition } from 'chart.js/helpers'

;(function (window, document) {
    let oilCanvas = document.getElementById("oilChart");
    if (oilCanvas) {
        let dataForGraphic = JSON.parse(oilCanvas.dataset.investors);
        console.log(dataForGraphic);
        let labels = [];
        let values = [];
        let colors = [];
        for (let i = 0; i < dataForGraphic.length; i++) {
            labels.push(dataForGraphic[i].fullName);
            values.push(dataForGraphic[i].amount);
            colors.push(dataForGraphic[i].color);
        }
        let oilData = {
            labels: labels,
            datasets: [
                {
                    data: values,
                    backgroundColor: colors
                }]
        };

        let pieChart = new Chart(oilCanvas, {
            type: 'pie',
            data: oilData,
            options: {
                plugins: {
                    legend: {
                        display: true,
                        position: 'left',
                        // labels: {
                        //     color: 'rgb(255, 99, 132)'
                        // }
                    }
                }
            }
        });
    }
})(window, document);
