;(function (window, document) {
    let investorColumns = document.getElementsByClassName('js-investor-columns');
    let investorColumn = investorColumns.length > 0 ? investorColumns[0] : null;

    if (investorColumn && window.location.hash) {
        let elements = document.querySelectorAll('.nav-link')
        elements.forEach(element => {
            element.classList.remove('active')
        })
        let tabs = document.querySelectorAll('.tab-pane')
        tabs.forEach(tab => {
            tab.classList.remove('show')
            tab.classList.remove('active')
        })
        let hash = window.location.hash
        document.getElementById(hash.replace('#', '')).classList.add('show')
        document.getElementById(hash.replace('#', '')).classList.add('active')
        let activeElement = investorColumn.querySelector(`a[href='${hash}']`)
        activeElement.classList.add('active')

        removeHash()
        function removeHash () {
            history.pushState("", document.title, window.location.pathname
                + window.location.search);
        }
    }

})(window, document);
